<template>
  <div
    id="campanha-nacional-registro-listagem"
    class="pb-5"
  >
    <confirm-delete-modal-campaign
      :modal-show="deleteModal.showModal"
      @confirmDelete="actionDeleteCampaign"
      @cancelDelete="closeModalDelete"
    />
    <PageHeader
      screen-name="Ver campanhas nacionais"
      :link-items="linkItems"
    />

    <div class="card">
      <b-row>
        <b-col md="12">
          <b-button
            class="mt-2 m-1 mx-2"
            variant="primary-button"
            @click="redirectToAdd"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-50"
            />
            Nova Campanha Nacional
          </b-button>
        </b-col>
      </b-row>

      <b-alert
        variant="primary"
        :show="tabela.erroTabela"
        class="mx-2 mt-5"
      >
        <div class="alert-body d-flex justify-content-center">
          <span class="text-primary">
            <strong
              class="text-primary"
            >Sistema de busca indisponível no momento.</strong>
          </span>
        </div>
      </b-alert>

      <Filters
        @actionSearch="handleSubmitFormFilters"
        @actionClear="clearFilters"
      >
        <validation-observer ref="formFilters">
          <b-form class="mx-2">  
            <b-row class="align-items-center">
              <b-col sm="6" lg="6">
                <b-form-group label="Nome da Campanha" label-for="nomeCampanha" label-class="font_size_label">
                  <validation-provider #default="{ errors }" name="nomeCampanha">
                    <b-form-input
                      id="nomeCampanha"
                      maxlength="50"
                      v-model="filtros.nomeCampanha"
                      placeholder="Insira o nome da campanha"
                      autocomplete="off"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col sm="6" lg="6">
                <b-form-group 
                  label="Data da Campanha"
                  label-for="dataCampanha"
                  :class="{'mt-1': errorDataCampanha}"
                  label-class="font_size_label"
                >
                  <validation-provider name="dataCampanha">
                    <b-input-group>
                      <b-form-input
                          class="form-control"
                          id="dataCampanha"
                          v-model="inputDataCampanha"
                          v-mask="'##/##/####'"
                          placeholder="00/00/0000"
                          autocomplete="off"
                          @input="setDataCampanhaInput"
                      />
                      <b-input-group-append>
                        <b-form-datepicker
                            class="form-control d-flex align-items-center"
                            locale="pt-BR"
                            v-model="filtros.dataCampanha"
                            button-only
                            right
                            :hide-header="true"
                            label-help="Selecione a data de inicio"
                            button-variant="custom-blue"
                            clearable
                            @input="setDataCampanhaPicker"
                        />
                      </b-input-group-append>
                    </b-input-group>
                  </validation-provider>
                    <small v-if="errorDataCampanha" class="text-danger">A data informada é inválida</small>
                </b-form-group>
              </b-col>
            </b-row> 
          </b-form>
        </validation-observer>

      </Filters>
      <div class="d-flex flex-row align-items-center mx-2 mb-2">
        <span
          class="mr-1"
          for="tableSize"
        >Mostrar</span>
        <v-select
          id="amounlinesVisualizer"
          v-model="paginationData.defaultSize"
          :options="tabela.quantidadeLinhasVisualizar"
          :clearable="false"
          @input="updateQtdView()"
        >
          <span slot="no-options">Nenhuma opção selecionável.</span>
        </v-select>
      </div>
      <div
        v-if="loadingTable"
        class="d-flex justify-content-center my-3"
      >
        <div
          class="spinner-border text-custom-blue"
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <section
        v-if="!loadingTable"
        class="list-campaign"
      >
        <b-table
          id="tableCampaign"
          responsive
          :sticky-column="true"
          :busy.sync="tabela.tabelaOcupada"
          :fields="tabela.fields"
          :items="tabela.items"
          no-local-sorting
          :no-sort-reset="true"
          @sort-changed="handleOrderTable"
        >
          <template #cell(inicio)="row">
            <span>
              {{ row.item.inicio ? moment(row.item.inicio).format("DD/MM/YYYY") : '' }}
              -
              {{ row.item.termino ? moment(row.item.termino).format("DD/MM/YYYY") : '' }}
            </span>
          </template>
          <template #cell(actions)="row">
            <span class="mr-1">
              <feather-icon
                v-if="viewCampaign"
                id="show-campanha"
                role="button"
                class="text-custom-blue mr-1"
                icon="EyeIcon"
                size="18"
                @click.prevent="redirectToShow(row.item)"
              />
              <feather-icon
                v-if="editCampaign"
                id="edit-campanha"
                role="button"
                class="text-custom-blue mr-1"
                icon="EditIcon"
                size="18"
                @click.prevent="redirectToEdit(row.item)"
              />
              <feather-icon
                v-if="deleteCampaign"
                id="delete-campanha"
                role="button"
                class="text-custom-blue"
                icon="TrashIcon"
                size="18"
                @click.prevent="modalDeleteCampaign(row.item)"
              />
            </span>
          </template>
        </b-table>
        <div class="pb-2 px-1">
          <span
            v-if="tabela.semDados"
            class="ml-1"
          >Nenhuma campanha encontrada</span>
          <b-col
            sm="12"
            class="mt-2"
          >
            <CustomPagination
              :paginacao="paginationData"
              @page-cliked="updateCurrentPage"
            />
          </b-col>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BFormDatepicker,
  BInputGroup,
  BInputGroupAppend,
  BRow,
  BCol,
  BButton,
  BTable,
  BAlert,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import moment from 'moment'
import CustomPagination from '@/views/components/custom/pagination/CustomPagination.vue'
import { actions, subjects } from '@/libs/acl/rules'
import { tipoCampanhaEnum } from '@/enum/tipoCampanha.js'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ConfirmDeleteModalCampaign from '../components/ConfirmDeleteModalCampaign.vue'
import {
  errorMessage,
  successCheckCircleMessageConfirmAction,
  triangleWarnMessage,
} from '@/libs/sweetalerts'
import PageHeader from '@/views/components/custom/page-header/PageHeader.vue'
import Filters from '@/views/components/custom/filters/Filters.vue'

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormDatepicker,
    BInputGroup,
    BInputGroupAppend,
    BRow,
    BCol,
    BButton,
    vSelect,
    BTable,
    CustomPagination,
    BAlert,
    ConfirmDeleteModalCampaign,
    PageHeader,
    Filters,
    ValidationProvider, 
    ValidationObserver
  },

  props: ['nameSavedCampaign'],

  data() {
    return {
      moment,
      linkItems: [
        {
          name: 'Campanhas nacionais',
          active: true,
        },
      ],
      arEditCampaign: this.$can(actions.ATUALIZAR, subjects.CAMPANHAS_SESI_UO),
      drEditCampaign: this.$can(actions.ATUALIZAR, subjects.CAMPANHAS_SESI_DR),
      viewCampaign: this.$can(actions.VISUALIZAR, subjects.CAMPANHAS),
      editCampaign: this.$can(actions.VISUALIZAR, subjects.CAMPANHAS),
      deleteCampaign: this.$can(actions.VISUALIZAR, subjects.CAMPANHAS),
      canEditCronograma: this.$can(
        actions.ATUALIZAR,
        subjects.CRONOGRAMA_CAMPANHA,
      ),

      registers: [],

      showTable: false,
      loadingTable: false,

      paginationData: {
        currentPage: 1,
        totalLines: 0,
        fromLine: 0,
        toLine: 0,
        defaultSize: 10,
      },

      inputDataCampanha: '',
      errorDataCampanha: false,

      filtros: {
        nomeCampanha: '',
        dataCampanha: '',
      },

      deleteModal: {
        showModal: false,
        idCampaign: null,
        situationCampaign: null,
      },

      modalSuccess: {
        showModal: false,
        typeModal: 'success',
        caseModal: 'edit-schedule',
      },

      tabela: {
        semDados: false,
        erroTabela: false,
        tabelaOcupada: false,
        quantidadeLinhasVisualizar: [10, 25, 50, 100],
        sort: '',
        columnOrder: '',
        fields: [
          { key: 'descricao', label: 'NOME', sortable: true },
          { key: 'inicio', label: 'PERÍODO', sortable: true },
          {
            key: 'actions',
            label: 'AÇÕES',
            class: 'width-custom-column text-center actions',
          },
        ],
        items: [],
      },
      campaignNotScheduled: '',
    }
  },

  mounted() {
    this.findAll()
  },

  methods: {
    async findAll(filter = false) {
      this.errorDataCampanha = false; 

      if (!filter) {
        this.tabela.erroTabela = false
        this.tabela.semDados = false
        this.loadingTable = true
      }

      await this.$http
        .get(this.$api.campanhaNacional(), { params: this.setParams() })
        .then(response => {
          if (response.status === 200) {
            if (response.data.data.length > 0) {
              this.tabela.items = response.data.data
              this.tabela.tabelaOcupada = false
              this.handlePagination(response.data)
              return
            }

            this.tabela.items = []

            this.tabela.semDados = true
            this.showTable = false
          }
        }).catch(({ response: { data } }) => { 
          const errorDataCampanha = !!data.errors.dataCampanha;

          if(!errorDataCampanha) {
            this.tabela.erroTabela = true
          }

          this.errorDataCampanha = errorDataCampanha;
        })

      this.loadingTable = false
      if (this.$props.nameSavedCampaign) {
        this.campaignNotScheduled = `Campanha ${this.$props.nameSavedCampaign} não lançada`
      }
    },

    handleOrderTable(context) {
      this.tabela.tabelaOcupada = true
      this.tabela.columnOrder = context.sortBy
      this.tabela.sort = context.sortDesc ? 'desc' : 'asc'

      this.findAll(true)
    },

    setParams() {
      return {
        colunaNome: this.tabela.columnOrder,
        colunaOrdem: this.tabela.sort,
        porPagina: this.paginationData.defaultSize,
        pagina: this.paginationData.currentPage,
        tipoCampanha: tipoCampanhaEnum.NACIONAL,
        
        descricaoCampanha: this.filtros.nomeCampanha,
        dataCampanha: this.filtros.dataCampanha
      }
    },

    handleSubmitFormFilters() {
      this.paginationData.currentPage = 1
      this.$refs.formFilters.validate()
        .then(success => {
          if (success) {
            this.findAll()
          }
        })
    },

    clearFilters() {
      this.filtros.nomeCampanha = '';
      this.filtros.dataCampanha = '';
      this.inputDataCampanha = '';

      this.errorDataCampanha = false;

      this.findAll();
    },

    handlePagination(data) {
      this.paginationData.fromLine = data.from
      this.paginationData.toLine = data.to
      this.paginationData.totalLines = data.total
      this.paginationData.currentPage = data.current_page
    },

    updateQtdView() {
      this.paginationData.currentPage = 1
      this.findAll()
    },

    updateCurrentPage(page) {
      this.paginationData.currentPage = page
      this.findAll()
    },

    redirectToAdd() {
      this.$router.push({ name: 'register-campanha-nacional' })
    },

    redirectToEdit({ id_campanha }) {
      this.$router.push({
        name: 'campanha-edit-nacional',
        params: {
          idCampanha: id_campanha,
        },
      })
    },

    redirectToShow({ id_campanha }) {
      this.$router.push({
        name: 'campaign-show-nacional',
        params: {
          idCampanha: id_campanha,
        },
      })
    },

    modalDeleteCampaign({ id_campanha }) {
      this.deleteModal.showModal = true
      this.deleteModal.idCampaign = id_campanha
    },

    closeModalDelete() {
      this.deleteModal.showModal = false
      this.deleteModal.idCampaign = null
    },

    actionDeleteCampaign() {
      this.$http.delete(this.$api.campanhaNacionalId(this.deleteModal.idCampaign))
        .then(() => {
          this.closeModalDelete()
          this.modalSuccessDeleteCampaign()
        })
        .catch(error => {
          this.closeModalDelete()
          this.loadingTable = false
          this.handleError(error.response)
        })
    },

    handleError(response) {
      const errors = response.status === 400 || response.status === 404

      if (errors) {
        triangleWarnMessage(
          response.data.errors,
        )

        return
      }

      triangleWarnMessage(
        'Não foi possível realizar a sua solicitação.<br /> Entre em contato com o suporte.',
      )
    },

    openModalErrorSave(data, status) {
      const title = 'Ocorreu um erro'
      const message = status === 500 ? 'Ocorreu um erro ao excluir a campanha' : data.errors

      errorMessage(title, message)
    },

    modalSuccessDeleteCampaign() {
      const title = 'Campanha excluída com sucesso!'

      successCheckCircleMessageConfirmAction(null, title).then(() => {
        this.paginationData.currentPage = 1
        this.findAll()
      })
    },

    setDataCampanhaInput(value) {
      this.inputDataCampanha = value;
      this.filtros.dataCampanha = value ?  moment(this.inputDataCampanha, 'DD/MM/YYYY').format('YYYY-MM-DD') : value;
    },

    setDataCampanhaPicker(value) {
      this.filtros.dataCampanha = value;
      this.inputDataCampanha = value ? moment(this.filtros.dataCampanha).format('DD/MM/YYYY') : value;
    },
  },
}
</script>

